import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import DataProtectionNotice from "../views/DataProtectionNotice.vue";
import CookieStatement from "../views/CookieStatement.vue";
import ServiceAgreementDescription from "../views/ServiceAgreementDescription.vue";
import NotFound from "../views/NotFound.vue";
import Requests from "../views/requests/Requests.vue";
import SubscriptionRequests from "../views/requests/SubscriptionRequests.vue";
import RequestDetails from "../views/requests/RequestDetails.vue";
import SubscriptionRequestDetails from "../views/requests/SubscriptionRequestDetails.vue";
import SonarServers from "../views/sonarServers/SonarServers.vue";
import SonarServerDetails from "../views/sonarServers/SonarServerDetails.vue";
import Organizations from "../views/organizations/Organizations.vue";
import OrganizationDetails from "../views/organizations/OrganizationDetails.vue";
import PortalGroupDetails from "../views/portalGroups/PortalGroupDetails.vue";
import PortalGroups from "../views/portalGroups/PortalGroups.vue";
import PortalUsers from "../views/portalUsers/PortalUsers.vue";
import PortalUserDetails from "../views/portalUsers/PortalUserDetails.vue";
import SonarGroupDetails from "../views/sonarGroups/SonarGroupDetails.vue";
import SonarGroups from "../views/sonarGroups/SonarGroups.vue";
import SonarUserDetails from "../views/sonarUsers/SonarUserDetails.vue";
import SonarUsers from "../views/sonarUsers/SonarUsers.vue";
import SonarProjects from "../views/sonarProjects/SonarProjects.vue";
import AuditLogs from "../views/audit/AuditLogs.vue";
import AuditLogsRequests from "../views/audit/AuditLogsRequests.vue";
import AuditLogsSonarServers from "../views/audit/AuditLogsSonarServers.vue";
import AuditLogsOrganizations from "../views/audit/AuditLogsOrganizations.vue";
import AuditLogsPortalGroups from "../views/audit/AuditLogsPortalGroups.vue";
import AuditLogsPortalUsers from "../views/audit/AuditLogsPortalUsers.vue";
import AuditLogsSonarProjects from "../views/audit/AuditLogsSonarProjects.vue";
import AuditLogsSonarGroups from "../views/audit/AuditLogsSonarGroups.vue";
import AuditLogsSonarUsers from "../views/audit/AuditLogsSonarUsers.vue";
import Subscriptions from "../views/subscriptions/Subscriptions.vue";
import SubscriptionDetails from "../views/subscriptions/SubscriptionDetails.vue";
import ManagementGroups from "../views/managementGroups/ManagementGroups.vue";
import CostCenters from "../views/costCenters/CostCenters.vue";
import CostCentersDetails from "../views/costCenters/CostCenterDetails.vue";
import ServiceAgreements from "../views/serviceAgreements/ServiceAgreements.vue";
import ServiceAgreementDetails from "../views/serviceAgreements/ServiceAgreementDetails.vue";
import AuditLogsSubscriptions from "../views/audit/AuditLogsSubscriptions.vue";
import AuditLogsManagementGroups from "../views/audit/AuditLogsManagementGroups.vue";
import AuditLogsCostCenters from "../views/audit/AuditLogsCostCenters.vue";
import ManagementGroupDetails from "../views/managementGroups/ManagementGroupDetails.vue";
import CostAtlassian from "@/views/costAtlassian/CostAtlassian.vue";
import CostAzureHub from "@/views/costAzureHub/CostAzureHub.vue";
import CostAzureSp from "@/views/costAzureSp/CostAzureSp.vue";
import CostAzureSub from "@/views/costAzureSub/CostAzureSub.vue";
import CostCloudExcellence from "@/views/costCloudExcellence/CostCloudExcellence.vue";
import CostDataDog from "@/views/costDataDog/CostDataDog.vue";
import CostKubecost from "@/views/costKubecost/CostKubecost.vue";
import CostAtlassianDetails from "@/views/costAtlassian/CostAtlassianDetails.vue";
import CostAzureHubDetails from "@/views/costAzureHub/CostAzureHubDetails.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/orgs/organizations",
    name: "Organizations",
    component: Organizations,
  },
  {
    path: "/orgs/groups",
    name: "PortalGroups",
    component: PortalGroups,
  },
  {
    path: "/orgs/users",
    name: "PortalUsers",
    component: PortalUsers,
  },
  {
    path: "/orgs/requests",
    name: "OrgRequests",
    component: Requests,
  },
  {
    path: "/azure/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
  },
  {
    path: "/azure/subscriptions/:id",
    name: "SubscriptionDetails",
    component: SubscriptionDetails,
  },
  {
    path: "/azure/requests",
    name: "SubscriptionRequests",
    component: SubscriptionRequests,
  },
  {
    path: "/azure/groups",
    name: "ManagementGroups",
    component: ManagementGroups,
  },
  {
    path: "/sonar/servers",
    name: "SonarServers",
    component: SonarServers,
  },
  {
    path: "/sonar/projects",
    name: "SonarProjects",
    component: SonarProjects,
  },
  {
    path: "/sonar/groups",
    name: "SonarGroups",
    component: SonarGroups,
  },
  {
    path: "/sonar/users",
    name: "SonarUsers",
    component: SonarUsers,
  },
  {
    path: "/costs/centers",
    name: "CostCenters",
    component: CostCenters,
  },
  {
    path: "/costs/service-agreements",
    name: "ServiceAgreements",
    component: ServiceAgreements,
  },
  {
    path: "/data-protection-notice",
    name: "DataProtectionNotice",
    component: DataProtectionNotice,
  },
  {
    path: "/cookie-statement",
    name: "CookieStatement",
    component: CookieStatement,
  },
  {
    path: "/service-agreement-description",
    name: "ServiceAgreementDescription",
    component: ServiceAgreementDescription,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/requests",
    name: "Requests",
    component: Requests,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/orgs/requests/:id",
    name: "RequestDetails",
    component: RequestDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/azure/requests/:id",
    name: "SubscriptionRequestDetails",
    component: SubscriptionRequestDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/sonar/servers/:id",
    name: "SonarServerDetails",
    component: SonarServerDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/orgs/organizations/:id",
    name: "OrganizationDetails",
    component: OrganizationDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/orgs/groups/:id",
    name: "PortalGroupDetails",
    component: PortalGroupDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/orgs/users/:id",
    name: "PortalUserDetails",
    component: PortalUserDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/costs/centers/:id",
    name: "CostCenterDetails",
    component: CostCentersDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/costs/centers/:id/atlassian/cost/:month",
    name: "CostAtlassianDetails",
    component: CostAtlassianDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/costs/centers/:id/azure/hub/cost/:month",
    name: "CostAzureHubDetails",
    component: CostAzureHubDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/costs/service-agreements/:id",
    name: "ServiceAgreementDetails",
    component: ServiceAgreementDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/costs/atlassian/",
    name: "CostAtlassian",
    component: CostAtlassian,
    meta: {
      requiresAuth: true,
      role: ["admin"],
    },
  },
  {
    path: "/costs/azure/hub/",
    name: "CostAzureHub",
    component: CostAzureHub,
    meta: {
      requiresAuth: true,
      role: ["admin"],
    },
  },
  {
    path: "/costs/azure/sp/",
    name: "CostAzureSp",
    component: CostAzureSp,
    meta: {
      requiresAuth: true,
      role: ["admin"],
    },
  },
  {
    path: "/costs/azure/subscriptions/",
    name: "CostAzureSub",
    component: CostAzureSub,
    meta: {
      requiresAuth: true,
      role: ["admin"],
    },
  },
  {
    path: "/costs/cloud-excellence/",
    name: "CostCloudExcellence",
    component: CostCloudExcellence,
    meta: {
      requiresAuth: true,
      role: ["admin"],
    },
  },
  {
    path: "/costs/datadog/",
    name: "CostDataDog",
    component: CostDataDog,
    meta: {
      requiresAuth: true,
      role: ["admin"],
    },
  },
  {
    path: "/costs/azure/kubecost/",
    name: "CostKubecost",
    component: CostKubecost,
    meta: {
      requiresAuth: true,
      role: ["admin"],
    },
  },
  {
    path: "/sonar/groups/:id",
    name: "SonarGroupDetails",
    component: SonarGroupDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/sonar/users/:id",
    name: "SonarUserDetails",
    component: SonarUserDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/azure/groups/:id",
    name: "ManagementGroupDetails",
    component: ManagementGroupDetails,
    meta: {
      requiresAuth: true,
      role: ["user"],
    },
  },
  {
    path: "/audit",
    name: "Audit",
    component: AuditLogs,
    meta: {
      requiresAuth: true,
      role: ["admin"],
    },
    children: [
      {
        path: "requests",
        name: "RequestsAudit",
        component: AuditLogsRequests,
      },
      {
        path: "sonar-servers",
        name: "SonarServersAudit",
        component: AuditLogsSonarServers,
      },
      {
        path: "organizations",
        name: "OrganizationsAudit",
        component: AuditLogsOrganizations,
      },
      {
        path: "portal-groups",
        name: "PortalGroupsAudit",
        component: AuditLogsPortalGroups,
      },
      {
        path: "portal-users",
        name: "PortalUsersAudit",
        component: AuditLogsPortalUsers,
      },
      {
        path: "sonar-projects",
        name: "SonarProjectsAudit",
        component: AuditLogsSonarProjects,
      },
      {
        path: "sonar-groups",
        name: "SonarGroupsAudit",
        component: AuditLogsSonarGroups,
      },
      {
        path: "sonar-users",
        name: "SonarUsersAudit",
        component: AuditLogsSonarUsers,
      },
      {
        path: "subscriptions",
        name: "SubscriptionsAudit",
        component: AuditLogsSubscriptions,
      },
      {
        path: "management-groups",
        name: "ManagementGroupsAudit",
        component: AuditLogsManagementGroups,
      },
      {
        path: "cost-centers",
        name: "CostCentersAudit",
        component: AuditLogsCostCenters,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

router.beforeEach(async (to, _from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // We wait for Keycloak init, then we can call all methods safely
    while (Vue.prototype.$keycloak.createLoginUrl === null) {
      await sleep(100);
    }
    if (Vue.prototype.$keycloak.authenticated) {
      next();
    } else {
      const loginUrl = Vue.prototype.$keycloak.createLoginUrl();
      window.location.replace(loginUrl);
    }
  } else {
    next();
  }
});

export default router;
