import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  emptyManagementGroup,
  InterfaceManagementGroup,
  InterfaceManagementGroupSearchRequest,
  InterfaceManagementGroupSearchResult,
} from "@/models/ManagementGroup.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/management-groups`;

interface FetchParams {
  search: string;
  page: number;
}

@Module({ namespaced: true })
class ManagementGroups extends VuexModule {
  public existingManagementGroups: InterfaceManagementGroup[] = [];
  public managementGroupsSelectList: InterfaceManagementGroup[] = [];
  public pageDataManagementGroups: PageData = emptyPageData();
  public pageDataSearchManagementGroups: PageData = emptyPageData();
  public selectedManagementGroup: InterfaceManagementGroup =
    emptyManagementGroup();
  public managementGroupProjectsPageData: PageData = emptyPageData();
  public foundManagementGroups: InterfaceManagementGroup[] = [];
  public managementGroupAlert: Alert = emptyAlert();
  public managementGroupTableAlert: Alert = emptyAlert();
  public managementGroupDetailAlert: Alert = emptyAlert();
  public inProgress = false;
  public managementGroupsAutocompleteLoadMore = false;
  public toggleRemoteIdField = false;

  @Mutation
  public setManagementGroups(data: InterfaceManagementGroup[]): void {
    this.existingManagementGroups = data;
  }

  @Mutation
  public setManagementGroupsSelectList(data: InterfaceManagementGroup[]): void {
    this.managementGroupsSelectList = data;
  }

  @Mutation
  public setLoadMore(boolean: boolean): void {
    this.managementGroupsAutocompleteLoadMore = boolean;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataManagementGroups.totalItems = pageData.totalItems;
  }

  @Mutation
  public setSearchPageData(pageData: PageData): void {
    this.pageDataSearchManagementGroups.totalPages = pageData.totalPages;
  }

  @Mutation
  public setSelectedManagementGroup(data: InterfaceManagementGroup): void {
    this.selectedManagementGroup = data;
  }

  @Mutation
  public setManagementGroupProjectsPageData(pageData: PageData): void {
    this.managementGroupProjectsPageData.totalItems = pageData.totalItems;
  }

  @Mutation
  public setFoundManagementGroups(data: InterfaceManagementGroup[]): void {
    this.foundManagementGroups = data;
  }

  @Mutation
  public setManagementGroupAlert(data: Alert): void {
    this.managementGroupAlert = data;
  }

  @Mutation
  public clearManagementGroupAlert(): void {
    this.managementGroupAlert = emptyAlert();
  }

  @Mutation
  public setManagementGroupTableAlert(data: Alert): void {
    this.managementGroupTableAlert = data;
  }

  @Mutation
  public clearManagementGroupTableAlert(): void {
    this.managementGroupTableAlert = emptyAlert();
  }

  @Mutation
  public setManagementGroupDetailAlert(data: Alert): void {
    this.managementGroupDetailAlert = data;
  }

  @Mutation
  public clearManagementGroupDetailAlert(): void {
    this.managementGroupDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Mutation
  public setToggleRemoteIdField(data: boolean): void {
    this.toggleRemoteIdField = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      alert = new Alert(error.response?.status, error.response?.data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setManagementGroupAlert", alert);
    return false;
  }

  @Action
  public async storeManagementGroup(
    managementGroup: InterfaceManagementGroup
  ): Promise<boolean> {
    if (this.toggleRemoteIdField) {
      return axios
        .post(`${baseApiUrl}/import`, {
          id: managementGroup.id,
          name: managementGroup.name,
          parentId: managementGroup.parentId,
        })
        .then(() => {
          return true;
        })
        .catch((error: AxiosError) => {
          return this.context.dispatch("handleError", error);
        });
    } else {
      return axios
        .post(`${baseApiUrl}`, {
          name: managementGroup.name,
          parentId: managementGroup.parentId,
          parentName: managementGroup.parentName,
        })
        .then(() => {
          return true;
        })
        .catch((error: AxiosError) => {
          return this.context.dispatch("handleError", error);
        });
    }
  }

  @Action
  public async getManagementGroups(pageData: PageData): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: `${pageData.options.page}`,
      pageSize: `${pageData.options.itemsPerPage}`,
    }).toString();
    this.context.commit("setManagementGroups", []);
    this.context.commit("setInProgress", true);
    this.context.commit("clearManagementGroupTableAlert");
    return axios
      .get(`${baseApiUrl}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setManagementGroups", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setManagementGroupTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getManagementGroup(id: string): Promise<boolean> {
    this.context.commit("setSelectedManagementGroup", emptyManagementGroup());
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedManagementGroup", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setManagementGroupDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async getManagementGroupSelectList(
    fetchParams: FetchParams
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: fetchParams.page + "",
      pageSize: `5`,
    }).toString();
    let searchKeyword = "";
    if (fetchParams.search != "" && fetchParams.search != null) {
      searchKeyword = "/search/".concat(fetchParams.search);
    }
    return axios
      .get(`${baseApiUrl}${searchKeyword}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit(
          "setManagementGroupsSelectList",
          response.data.list
        );
        this.context.commit("setSearchPageData", response.data.paging);
        if (response.data.paging.pageIndex < response.data.paging.totalPages) {
          this.context.commit("setLoadMore", true);
        } else {
          this.context.commit("setLoadMore", false);
        }
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  public async updateManagementGroup(
    managementGroup: InterfaceManagementGroup
  ): Promise<boolean> {
    return axios
      .patch(`${baseApiUrl}/rename/${managementGroup.id}`, managementGroup)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async moveManagementGroup(
    managementGroup: InterfaceManagementGroup
  ): Promise<boolean> {
    return axios
      .patch(`${baseApiUrl}/move/${managementGroup.id}`, managementGroup)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async deleteManagementGroup(
    managementGroup: InterfaceManagementGroup
  ): Promise<boolean> {
    return axios
      .delete(`${baseApiUrl}/${managementGroup.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async searchManagementGroup(
    searchRequest: InterfaceManagementGroupSearchRequest
  ): Promise<InterfaceManagementGroupSearchResult> {
    this.context.commit("setFoundManagementGroups", []);
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageIndex}`,
      pageSize: `${searchRequest.pageSize}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}/search/${searchRequest.name}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setFoundManagementGroups", response.data.list);
        return <InterfaceManagementGroupSearchResult>{
          paging: response.data.paging,
          managementGroups: response.data.list,
        };
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return <InterfaceManagementGroupSearchResult>{};
      });
  }
}

export default ManagementGroups;
