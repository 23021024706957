import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "./models/PageData.model";
import { InterfacePortalUser } from "./models/PortalUser.model";
import { InterfaceSonarServer } from "./models/SonarServer.model";
import router from "./router";

import environmentConfig from "@/utils/environmentConfig";

const sonarServers = namespace("SonarServers");
const portalUsers = namespace("PortalUsers");

@Component({})
export default class App extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public userRole = "";
  public drawer = true;
  public darkMode = false;
  public hidden = true;
  public maintenance = false;
  public updateExists = false;
  public frontendVersion = "";
  public websocketClient: WebSocket | undefined;
  public frontendRevision = "";
  public termsAndConditionsDialog: boolean =
    this.showTermsAndConditionsDialog();

  @portalUsers.State
  public loggedInPortalUser!: InterfacePortalUser;

  @sonarServers.State
  public existingSonarServers!: InterfaceSonarServer[];

  @sonarServers.State
  public pageDataMaintenance!: PageData;

  @portalUsers.Action
  public getLoggedInPortalUser!: () => Promise<boolean>;

  @sonarServers.Action
  public getSonarServers!: (pageData: PageData) => Promise<boolean>;

  // No preselected Tab
  tab = null;

  services = this.servicesLink();

  links: (string | boolean)[][] = [[]];

  orgLinks = [
    [
      "mdi-group",
      "Organizations",
      "/orgs/organizations",
      "drawerOrganizations",
    ],
    [
      "mdi-account-multiple",
      "Portal Groups",
      "/orgs/groups",
      "drawerPortalGroups",
    ],
    [
      "mdi-account-outline",
      "Portal Users",
      "/orgs/users",
      "drawerPortalUsers",
      this.hidden,
    ],
    ["mdi-receipt-text-check", "Requests", "/orgs/requests", "drawerRequests"],
  ];
  azureLinks = [
    [
      "mdi-file-sign",
      "Subscriptions",
      "/azure/subscriptions",
      "drawerSubscriptions",
    ],
    [
      "mdi-receipt-text-check",
      "Subscription Requests",
      "/azure/requests",
      "drawerSubscriptionRequests",
    ],
    [
      "mdi-family-tree",
      "Management Groups",
      "/azure/groups",
      "drawerManagementGroupRequest",
      this.hidden,
    ],
  ];
  sonarLinks = [
    ["mdi-server", "Sonar Servers", "/sonar/servers", "drawerSonarServers"],
    [
      "mdi-application-brackets",
      "Sonar Projects",
      "/sonar/projects",
      "drawerProjects",
    ],
    ["mdi-account-group", "Sonar Groups", "/sonar/groups", "drawerSonarGroups"],
    ["mdi-account", "Sonar Users", "/sonar/users", "drawerSonarUsers"],
  ];
  costsLinks = [
    ["mdi-store", "Cost Centers", "/costs/centers", "drawerCosts"],
    [
      "mdi-file-sign",
      "Service Agreements",
      "/costs/service-agreements",
      "drawerServiceAgreements",
    ],
    [
      "mdi-jira",
      "Atlassian Costs",
      "/costs/atlassian",
      "drawerJiraProjects",
      this.hidden,
    ],
    [
      "mdi-hub",
      "Azure Hub Costs",
      "/costs/azure/hub",
      "drawerAzureHubCosts",
      this.hidden,
    ],
    [
      "mdi-robot",
      "Azure SP Costs",
      "/costs/azure/sp",
      "drawerAzureSPCosts",
      this.hidden,
    ],
    [
      "mdi-microsoft-azure",
      "Azure Sub Costs",
      "/costs/azure/subscriptions",
      "drawerAzureCosts",
      this.hidden,
    ],
    [
      "mdi-cloud-check",
      "Cloud Excellence Costs",
      "/costs/cloud-excellence",
      "drawerCloudExcellence",
      this.hidden,
    ],
    [
      "mdi-dog-side",
      "DataDog Costs",
      "/costs/datadog",
      "drawerDataDogCosts",
      this.hidden,
    ],
    [
      "mdi-kubernetes",
      "mCAP Costs",
      "/costs/azure/kubecost",
      "drawerKubeCosts",
      this.hidden,
    ],
  ];

  created(): void {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    window.addEventListener("popstate", () => {
      // When browser back button is clicked
      this.changeLinks();
    });
  }

  updateAvailable(): void {
    this.updateExists = true;
  }

  mounted(): void {
    if (localStorage.darkMode) {
      this.darkMode = JSON.parse(localStorage.darkMode);
      this.$vuetify.theme.dark = JSON.parse(localStorage.darkMode);
    }

    this.userRole = Vue.prototype.$keycloak.realmAccess.roles
      .filter(function (str: string) {
        return str.includes("user") || str.includes("admin");
      })[0]
      .toUpperCase();

    this.changeLinks();
    this.getLoggedInPortalUser();
    this.checkMaintenance();

    const baseApiUrl = `${environmentConfig(
      "VUE_APP_BACKEND_PATH"
    )}/websocket/UserName`;
    const wsBaseApiUrl = baseApiUrl.replace("http", "ws");
    // eslint-disable-next-line no-console
    console.log("Connecting to websocket Server now...");
    // eslint-disable-next-line no-console
    console.log(`${wsBaseApiUrl}`);
    this.websocketClient = new WebSocket(`${wsBaseApiUrl}`);
    this.websocketClient.onopen = function (event) {
      this.send("Ping");
      // eslint-disable-next-line no-console
      console.log(event);
      // eslint-disable-next-line no-console
      console.log("Success!");
    };
    this.websocketClient.onmessage = function (event) {
      // eslint-disable-next-line no-console
      console.log(event.data);
    };
  }

  public servicesLink(): string[][] {
    const servicesUser = [
      ["Organizations", "/orgs/organizations"],
      ["Azure", "/azure/subscriptions"],
      ["SonarQube", "/sonar/servers"],
      ["Costs", "/costs/centers"],
    ];

    const servicesAdmin = [[]]; // Add admin services here
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return servicesUser.concat(servicesAdmin);
    }
    return servicesUser;
  }

  public changeLinks(): void {
    if (this.$router.currentRoute.fullPath.startsWith("/orgs"))
      this.links = [...this.orgLinks];
    else if (this.$router.currentRoute.fullPath.startsWith("/azure"))
      this.links = [...this.azureLinks];
    else if (this.$router.currentRoute.fullPath.startsWith("/sonar"))
      this.links = [...this.sonarLinks];
    else if (this.$router.currentRoute.fullPath.startsWith("/costs"))
      this.links = [...this.costsLinks];
    else this.links = [...this.orgLinks];
  }

  public checkMaintenance(): void {
    this.getSonarServers(this.pageDataMaintenance).then(() => {
      this.checkAndSetServerMaintenance();
      for (let i = 2; i <= this.pageDataMaintenance.totalPages; i++) {
        this.pageDataMaintenance.options.page = i;
        this.getSonarServers(this.pageDataMaintenance).then(() => {
          this.checkAndSetServerMaintenance();
        });
      }
    });
    this.$root.$on("maintenance", (value: boolean) => {
      this.maintenance = value;
    });
  }

  private checkAndSetServerMaintenance() {
    this.existingSonarServers.forEach((sonarServer) => {
      if (sonarServer.maintenance) {
        this.maintenance = true;
      }
    });
  }

  public reload(): void {
    window.location.reload();
  }

  public goToServers(): void {
    router.push({ name: "SonarServers" });
  }

  public toggleDarkMode(): void {
    this.darkMode = !this.darkMode;
    this.$vuetify.theme.dark = this.darkMode;
    localStorage.darkMode = this.darkMode;
  }

  public showTermsAndConditionsDialog(): boolean {
    if (localStorage.getItem("termsAndConditionsAgreed") === "true") {
      return false;
    }
    return true;
  }

  public agreeTermsAndConditionsDialog(): void {
    localStorage.termsAndConditionsAgreed = "true";
    this.termsAndConditionsDialog = false;
  }

  public goToAuditLogs(): void {
    if (this.$router.currentRoute.fullPath.includes("audit") === false) {
      router.push({ name: "RequestsAudit" });
    }
  }
}
