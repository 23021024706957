import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  InterfaceCostAtlassian,
  emptyCostAtlassian,
} from "@/models/CostAtlassian.model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse } from "axios";
import environmentConfig from "@/utils/environmentConfig";

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/atlassian`;

@Module({ namespaced: true })
class CostAtlassian extends VuexModule {
  public selectedAtlassianCost: InterfaceCostAtlassian = emptyCostAtlassian();
  public selectedAtlassianCostsList: InterfaceCostAtlassian[] = [];
  public atlassianCostDetailAlert: Alert = emptyAlert();
  public costAtlassianTableAlert: Alert = emptyAlert();
  public confluenceUsers: string[] = [];
  public jiraSoftwareUsers: string[] = [];
  public jiraServiceManagementUsers: string[] = [];
  public billingMonth = "";
  public rangeDate: string[] = [];

  @Mutation
  public setConfluenceUsers(data: string[]): void {
    this.confluenceUsers = data;
  }

  @Mutation
  public setJiraSoftwareUsers(data: string[]): void {
    this.jiraSoftwareUsers = data;
  }

  @Mutation
  public setJiraServiceManagementUsers(data: string[]): void {
    this.jiraServiceManagementUsers = data;
  }

  @Mutation
  public setSelectedAtlassianCost(data: InterfaceCostAtlassian): void {
    this.selectedAtlassianCost = data;
  }

  @Mutation
  public setSelectedAtlassianCostsList(data: InterfaceCostAtlassian[]): void {
    this.selectedAtlassianCostsList = data;
  }

  @Mutation
  public setAtlassianCostDetailAlert(data: Alert): void {
    this.atlassianCostDetailAlert = data;
  }

  @Mutation
  public setAtlassianCostListAlert(data: Alert): void {
    this.costAtlassianTableAlert = data;
  }

  @Mutation
  public clearAtlassianCostListAlert(): void {
    this.costAtlassianTableAlert = emptyAlert();
  }

  @Mutation
  public setBillingMonth(data: string): void {
    this.billingMonth = data;
  }
  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  async getAtlassianCosts(): Promise<boolean> {
    const allDates: InterfaceCostAtlassian[] = [];
    try {
      await Promise.all(
        this.rangeDate.map((date) => {
          return axios
            .get(`${baseApiUrl}?month=${date}`)
            .then((response: AxiosResponse) => {
              // For each item in response data push to allDates
              response.data.forEach((item: InterfaceCostAtlassian) => {
                allDates.push(item);
              });
            });
        })
      );
      this.context.commit("setSelectedAtlassianCostsList", allDates);
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      let alert: Alert;
      if (axiosError.response) {
        alert = new Alert(
          axiosError.response.status,
          axiosError.response.data.message
        );
      } else {
        alert = new Alert(0, axiosError.message);
      }
      this.context.commit("setAtlassianCostListAlert", alert, { root: true });
      return false;
    }
  }

  @Action
  public async getAtlassianCost(fetchParams: FetchParams): Promise<boolean> {
    const params = new URLSearchParams({
      costCenter: fetchParams.costCenter + "",
      billingMonth: fetchParams.billingMonth,
    }).toString();
    this.context.commit("setSelectedAtlassianCost", emptyCostAtlassian());
    return axios
      .get(`${baseApiUrl}/report?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedAtlassianCost", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setAtlassianCostDetailAlert", alert);
        return false;
      });
  }
}

export default CostAtlassian;
