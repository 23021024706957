import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  emptySubscription,
  InterfaceSubscription,
} from "@/models/Subscription.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import { SearchByKeywordRequest } from "@/models/Search.model";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/subscriptions`;

interface FetchParams {
  pageData: PageData;
  parentId: string;
}

@Module({ namespaced: true })
class Subscriptions extends VuexModule {
  public existingSubscriptions: InterfaceSubscription[] = [];
  public pageDataSubscriptions: PageData = emptyPageData();
  public subscriptionsByCostCenter: InterfaceSubscription[] = [];
  public pageDataSubscriptionsByCostCenter: PageData = emptyPageData();
  public selectedSubscription: InterfaceSubscription = emptySubscription();
  public subscriptionProjectsPageData: PageData = emptyPageData();
  public foundSubscriptions: InterfaceSubscription[] = [];
  public subscriptionAlert: Alert = emptyAlert();
  public subscriptionTableAlert: Alert = emptyAlert();
  public subscriptionDetailAlert: Alert = emptyAlert();
  public inProgress = false;
  public azurePrincipalId = "";

  @Mutation
  public setSubscriptions(data: InterfaceSubscription[]): void {
    this.existingSubscriptions = data;
  }

  @Mutation
  public setSubscriptionsByCostCenter(data: InterfaceSubscription[]): void {
    this.subscriptionsByCostCenter = data;
  }
  @Mutation
  public setAzurePrincipalId(data: string): void {
    this.azurePrincipalId = data;
  }
  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataSubscriptions.totalItems = pageData.totalItems;
  }

  @Mutation
  public setPageDataByCostCenter(pageData: PageData): void {
    this.pageDataSubscriptionsByCostCenter.totalItems = pageData.totalItems;
  }

  @Mutation
  public setSelectedSubscription(data: InterfaceSubscription): void {
    this.selectedSubscription = data;
  }

  @Mutation
  public setSubscriptionProjectsPageData(pageData: PageData): void {
    this.subscriptionProjectsPageData.totalItems = pageData.totalItems;
  }

  @Mutation
  public setFoundSubscriptions(data: InterfaceSubscription[]): void {
    this.foundSubscriptions = data;
  }

  @Mutation
  public setSubscriptionAlert(data: Alert): void {
    this.subscriptionAlert = data;
  }

  @Mutation
  public clearSubscriptionAlert(): void {
    this.subscriptionAlert = emptyAlert();
  }

  @Mutation
  public setSubscriptionTableAlert(data: Alert): void {
    this.subscriptionTableAlert = data;
  }

  @Mutation
  public clearSubscriptionTableAlert(): void {
    this.subscriptionTableAlert = emptyAlert();
  }

  @Mutation
  public setSubscriptionDetailAlert(data: Alert): void {
    this.subscriptionDetailAlert = data;
  }

  @Mutation
  public clearSubscriptionDetailAlert(): void {
    this.subscriptionDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      alert = new Alert(error.response?.status, error.response?.data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setSubscriptionAlert", alert);
    return false;
  }

  @Action
  public async storeSubscription(
    subscription: InterfaceSubscription
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}`, {
        name: subscription.name,
        initialOwner: subscription.initialOwnerId,
        subscriptionType: subscription.subscriptionType,
        organizationId: subscription.organizationId,
        managementGroupId: subscription.managementGroupId,
        costCenterId: subscription.costCenterId,
      })
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getSubscriptions(
    searchRequest: SearchByKeywordRequest
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageData.options.page}`,
      pageSize: `${searchRequest.pageData.options.itemsPerPage}`,
    }).toString();
    this.context.commit("setInProgress", true);
    this.context.commit("clearSubscriptionTableAlert");
    let searchKeyword = "";
    if (searchRequest.keyword != "" && searchRequest.keyword != undefined) {
      searchKeyword = "/search/" + searchRequest.keyword;
    }
    return axios
      .get(`${baseApiUrl}${searchKeyword}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSubscriptions", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setSubscriptionTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getSubscription(id: string): Promise<boolean> {
    this.context.commit("setSelectedSubscription", emptySubscription());
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedSubscription", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setSubscriptionDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async getSubscriptionsByCostCenter(
    fetchParams: FetchParams
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: `${fetchParams.pageData.pageIndex}` + "",
      pageSize: `${fetchParams.pageData.pageSize}`,
    }).toString();
    this.context.commit("setInProgress", true);
    this.context.commit("clearSubscriptionTableAlert");
    return axios
      .get(`${baseApiUrl}/cost-centers/${fetchParams.parentId}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSubscriptionsByCostCenter", response.data.list);
        this.context.commit("setPageDataByCostCenter", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setSubscriptionTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async updateSubscription(
    subscription: InterfaceSubscription
  ): Promise<boolean> {
    return axios
      .patch(`${baseApiUrl}/${subscription.id}/name`, subscription)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async changeSubscriptionManagementGroup(
    subscription: InterfaceSubscription
  ): Promise<boolean> {
    return axios
      .patch(`${baseApiUrl}/${subscription.id}/management-group`, subscription)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async changeSubscriptionOrganization(
    subscription: InterfaceSubscription
  ): Promise<boolean> {
    return axios
      .patch(`${baseApiUrl}/${subscription.id}/organization`, subscription)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async addOwnerToSubscription(
    subscriptionId: string
  ): Promise<boolean> {
    return axios
      .post(
        `${baseApiUrl}/${subscriptionId}/ownership/${this.azurePrincipalId}`
      )
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }
  @Action
  public async updateSubscriptionCostCenter(
    subscription: InterfaceSubscription
  ): Promise<boolean> {
    return axios
      .patch(`${baseApiUrl}/${subscription.id}/cost-center`, subscription)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async deleteSubscription(
    subscription: InterfaceSubscription
  ): Promise<boolean> {
    return axios
      .delete(`${baseApiUrl}/${subscription.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async cancelSubscription(
    subscription: InterfaceSubscription
  ): Promise<boolean> {
    return axios
      .patch(`${baseApiUrl}/${subscription.id}/cancel`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }
  @Action
  public async undoCancelSubscription(
    subscription: InterfaceSubscription
  ): Promise<boolean> {
    return axios
      .patch(`${baseApiUrl}/${subscription.id}/enable`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }
}

export default Subscriptions;
