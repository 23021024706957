import { emptyPageData, PageData } from "./PageData.model";

export interface SearchByKeywordRequest {
  keyword: string;
  pageData: PageData;
}

export const emptySearchByKeywordRequest = (): SearchByKeywordRequest => ({
  keyword: "",
  pageData: emptyPageData(),
});
