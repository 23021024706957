import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  InterfaceCostDataDog,
  emptyCostDataDog,
} from "@/models/CostDataDog.model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse } from "axios";
import environmentConfig from "@/utils/environmentConfig";

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/datadog`;

@Module({ namespaced: true })
class CostDataDog extends VuexModule {
  public selectedDataDogCost: InterfaceCostDataDog = emptyCostDataDog();
  public dataDogCostDetailAlert: Alert = emptyAlert();
  public costDataDogTableAlert: Alert = emptyAlert();
  public selectedDataDogCostsList: InterfaceCostDataDog[] = [];
  public billingMonth = "";
  public rangeDate: string[] = [];

  @Mutation
  public setSelectedDataDogCost(data: InterfaceCostDataDog): void {
    this.selectedDataDogCost = data;
  }

  @Mutation
  public setDataDogCostDetailAlert(data: Alert): void {
    this.dataDogCostDetailAlert = data;
  }

  @Mutation
  public setSelectedDataDogCostsList(data: InterfaceCostDataDog[]): void {
    this.selectedDataDogCostsList = data;
  }

  @Mutation
  public setDataDogCostListAlert(data: Alert): void {
    this.costDataDogTableAlert = data;
  }

  @Mutation
  public clearDataDogCostListAlert(): void {
    this.costDataDogTableAlert = emptyAlert();
  }

  @Mutation
  public setBillingMonth(data: string): void {
    this.billingMonth = data;
  }
  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  async getDataDogCosts(): Promise<boolean> {
    const allDates: InterfaceCostDataDog[] = [];
    try {
      await Promise.all(
        this.rangeDate.map((date) => {
          return axios
            .get(`${baseApiUrl}?month=${date}`)
            .then((response: AxiosResponse) => {
              // For each item in response data push to allDates
              response.data.forEach((item: InterfaceCostDataDog) => {
                allDates.push(item);
              });
            });
        })
      );
      this.context.commit("setSelectedDataDogCostsList", allDates);
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      let alert: Alert;
      if (axiosError.response) {
        alert = new Alert(
          axiosError.response.status,
          axiosError.response.data.message
        );
      } else {
        alert = new Alert(0, axiosError.message);
      }
      this.context.commit("setDataDogCostListAlert", alert, { root: true });
      return false;
    }
  }

  @Action
  public async getDataDogCost(fetchParams: FetchParams): Promise<boolean> {
    const params = new URLSearchParams({
      costCenter: fetchParams.costCenter + "",
      billingMonth: fetchParams.billingMonth,
    }).toString();
    this.context.commit("setSelectedDataDogCost", emptyCostDataDog());
    return axios
      .get(`${baseApiUrl}/report?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedDataDogCost", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setDataDogCostDetailAlert", alert);
        return false;
      });
  }
}

export default CostDataDog;
