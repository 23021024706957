import { PortalUser } from "./PortalUser.model";
import { PageData } from "./PageData.model";

export interface InterfaceCostCenter {
  id: string;
  name: string;
  responsibleId: string;
  responsibleEmail: string;
  organizationId: string;
  organizationName: string;
  notificationUsers: PortalUser[];
}

export interface InterfaceCostCenterAudit {
  rev: number;
  id: string;
  name: string;
  responsibleId: string;
  responsibleEmail: string;
  notificationUsers: PortalUser[];
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptyCostCenter = (): CostCenter => ({
  id: "",
  name: "",
  responsibleId: "",
  responsibleEmail: "",
  organizationId: "",
  organizationName: "",
  notificationUsers: [],
});

export class CostCenter implements InterfaceCostCenter {
  constructor(
    public id: string,
    public name: string,
    public responsibleId: string,
    public responsibleEmail: string,
    public organizationId: string,
    public organizationName: string,
    public notificationUsers: PortalUser[]
  ) {}
}

export interface InterfaceCostCenterSearchRequest {
  name: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfaceCostCenterSearchResult {
  paging: PageData;
  costCenters: InterfaceCostCenter[];
}

export interface InterfaceService {
  serviceName: string;
  serviceType: string;
  serviceTypeDescription: string;
  sourceCostCenter: string;
  cost: number;
}

export interface InterfaceCostCenterServicesResult {
  costCenter: string;
  costCenterResponsible: string;
  billingMonth: string;
  totalCost: number;
  currency: string;
  chargedBy: string;
  services: InterfaceService[];
}
