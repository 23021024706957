export interface InterfaceCostAtlassian {
  id: string;
  creation: string;
  billingMonth: string;
  totalCost: number;
  currency: string;
  serviceId: string;
  chargedBy: string;
  costCenter: string;
  costCenterResponsible: string;
  costCenterResponsibleEmailAddress: string;
  costCenterNotificationUsers: string[];
  details: InterfaceCostAtlassianDetails;
}

export interface InterfaceCostAtlassianDetails {
  atlassianServiceTypeMembers: InterfaceCostAtlassianServiceTypeMember;
}

export interface InterfaceCostAtlassianServiceTypeMember {
  MBM_CONFLUENCE_USERS: InterfaceAtlassianUser[];
  MBM_JIRA_SOFTWARE_USERS: InterfaceAtlassianUser[];
  MBM_JIRA_SERVICEMANAGEMENT_USERS: InterfaceAtlassianUser[];
}

export interface InterfaceAtlassianUser {
  emailAddress: string;
}

export const emptyCostAtlassian = (): InterfaceCostAtlassian => ({
  id: "",
  creation: "",
  billingMonth: "",
  totalCost: 0,
  currency: "",
  serviceId: "",
  chargedBy: "",
  costCenter: "",
  costCenterResponsible: "",
  costCenterResponsibleEmailAddress: "",
  costCenterNotificationUsers: [],
  details: emptyCostAtlassianDetails(),
});

export const emptyCostAtlassianDetails = (): InterfaceCostAtlassianDetails => ({
  atlassianServiceTypeMembers: emptyCostAtlassianServiceTypeMember(),
});

export const emptyCostAtlassianServiceTypeMember =
  (): InterfaceCostAtlassianServiceTypeMember => ({
    MBM_CONFLUENCE_USERS: [],
    MBM_JIRA_SOFTWARE_USERS: [],
    MBM_JIRA_SERVICEMANAGEMENT_USERS: [],
  });

export class CostAtlassian implements InterfaceCostAtlassian {
  constructor(
    public id: string,
    public creation: string,
    public billingMonth: string,
    public totalCost: number,
    public currency: string,
    public serviceId: string,
    public chargedBy: string,
    public costCenter: string,
    public costCenterResponsible: string,
    public costCenterResponsibleEmailAddress: string,
    public costCenterNotificationUsers: string[],
    public details: InterfaceCostAtlassianDetails
  ) {}
}
