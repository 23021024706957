import { PageData } from "./PageData.model";

export interface InterfaceServiceAgreement {
  id: string;
  amount: number;
  billingPeriodEnd: Date | null;
  billingPeriodStart: Date | null;
  companyName: string;
  costBreakdown: boolean;
  costCenter: string;
  costCenterAdditionalResponsible: string;
  costCenterAdditionalResponsibleEmailAddress: string;
  costCenterResponsible: string;
  costCenterResponsibleEmailAddress: string;
  currency: string;
  customerContactPerson: string;
  customerContactPersonEmailAddress: string;
  dataProcessingAgreement: boolean;
  dateSent: Date;
  dateSigned: Date;
  departmentName: string;
  organizationId: string;
  organizationName: string;
  paymentSchedule: string;
  serviceAgreementId: string;
  c4mbId: string;
  serviceDescription: string;
  serviceName: string;
  serviceProviderSignee: string;
  serviceProviderSigneeEmailAddress: string;
  serviceProviderAdditionalSignee: string;
  serviceProviderAdditionalSigneeEmailAddress: string;
  serviceProviderCompanyName: string;
  serviceProviderContactPerson: string;
  serviceProviderContactPersonEmailAddress: string;
  serviceProviderCostCenter: string;
  serviceProviderDepartmentName: string;
  status: string;
}

export interface InterfaceServiceAgreementAudit {
  rev: number;
  id: string;
  serviceAgreementId: string;
  c4mbId: string;
  costCenterResponsible: string;
  costCenterResponsibleEmailAddress: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptyServiceAgreement = (): ServiceAgreement => ({
  id: "",
  amount: 0,
  billingPeriodStart: null,
  billingPeriodEnd: null,
  companyName: "",
  costBreakdown: false,
  costCenter: "",
  costCenterAdditionalResponsible: "",
  costCenterAdditionalResponsibleEmailAddress: "",
  costCenterResponsible: "",
  costCenterResponsibleEmailAddress: "",
  currency: "",
  customerContactPerson: "",
  customerContactPersonEmailAddress: "",
  dataProcessingAgreement: false,
  dateSent: new Date(),
  dateSigned: new Date(),
  departmentName: "",
  organizationId: "",
  organizationName: "",
  paymentSchedule: "",
  serviceAgreementId: "",
  c4mbId: "",
  serviceDescription: "",
  serviceName: "",
  serviceProviderSignee: "",
  serviceProviderSigneeEmailAddress: "",
  serviceProviderAdditionalSignee: "",
  serviceProviderAdditionalSigneeEmailAddress: "",
  serviceProviderCompanyName: "",
  serviceProviderContactPerson: "",
  serviceProviderContactPersonEmailAddress: "",
  serviceProviderCostCenter: "",
  serviceProviderDepartmentName: "",
  status: "",
});

export class ServiceAgreement implements InterfaceServiceAgreement {
  constructor(
    public id: string,
    public amount: number,
    public billingPeriodEnd: Date | null,
    public billingPeriodStart: Date | null,
    public companyName: string,
    public costBreakdown: boolean,
    public costCenter: string,
    public costCenterAdditionalResponsible: string,
    public costCenterAdditionalResponsibleEmailAddress: string,
    public costCenterResponsible: string,
    public costCenterResponsibleEmailAddress: string,
    public currency: string,
    public customerContactPerson: string,
    public customerContactPersonEmailAddress: string,
    public dataProcessingAgreement: boolean,
    public dateSent: Date,
    public dateSigned: Date,
    public departmentName: string,
    public organizationId: string,
    public organizationName: string,
    public paymentSchedule: string,
    public serviceAgreementId: string,
    public c4mbId: string,
    public serviceDescription: string,
    public serviceName: string,
    public serviceProviderSignee: string,
    public serviceProviderSigneeEmailAddress: string,
    public serviceProviderAdditionalSignee: string,
    public serviceProviderAdditionalSigneeEmailAddress: string,
    public serviceProviderCompanyName: string,
    public serviceProviderContactPerson: string,
    public serviceProviderContactPersonEmailAddress: string,
    public serviceProviderCostCenter: string,
    public serviceProviderDepartmentName: string,
    public status: string
  ) {}
}

export interface InterfaceServiceAgreementSearchRequest {
  serviceAgreementId: string;
  pageIndex: number;
  pageSize: number;
}

export interface InterfaceServiceAgreementSearchResult {
  paging: PageData;
  serviceAgreements: InterfaceServiceAgreement[];
}
