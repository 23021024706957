import {
  emptySonarGroup,
  InterfaceSonarGroup,
  InterfacePagedSonarGroupMembership,
  InterfaceSonarGroupSearchResult,
  InterfaceSonarGroupSearchRequest,
} from "@/models/SonarGroup.model";
import { InterfaceSonarUser } from "@/models/SonarUser.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import environmentConfig from "@/utils/environmentConfig";
import { Alert, emptyAlert } from "@/models/Alert.model";
import { SearchByKeywordRequest } from "@/models/Search.model";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/sonar-groups`;

@Module({ namespaced: true })
class SonarGroups extends VuexModule {
  public existingSonarGroups: InterfaceSonarGroup[] = [];
  public pageDataSonarGroups: PageData = emptyPageData();
  public selectedSonarGroup: InterfaceSonarGroup = emptySonarGroup();
  public pageDataSonarGroupUsers: PageData = emptyPageData();
  public sonarGroupUserMembership: InterfaceSonarUser[] = [];
  public foundSonarGroups: InterfaceSonarGroupSearchResult[] = [];
  public sonarGroupAlert: Alert = emptyAlert();
  public sonarGroupTableAlert: Alert = emptyAlert();
  public sonarGroupDetailAlert: Alert = emptyAlert();
  public inProgress = false;
  public sonarServerId = "";
  public forceDelete = false;
  public notExistingSonarGroup = false;

  @Mutation
  public setSonarGroups(data: InterfaceSonarGroup[]): void {
    this.existingSonarGroups = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataSonarGroups.totalItems = pageData.totalItems;
  }

  @Mutation
  public setPageDataSonarGroupUsers(pageData: PageData): void {
    this.pageDataSonarGroupUsers.totalItems = pageData.totalItems;
  }

  @Mutation
  public setSelectedSonarGroup(data: InterfaceSonarGroup): void {
    this.selectedSonarGroup = data;
  }

  @Mutation
  public setSonarGroupUserMembership(data: InterfaceSonarUser[]): void {
    this.sonarGroupUserMembership = data;
  }

  @Mutation
  public setFoundSonarGroups(data: InterfaceSonarGroupSearchResult[]): void {
    this.foundSonarGroups = data;
  }

  @Mutation
  public setSonarGroupAlert(data: Alert): void {
    this.sonarGroupAlert = data;
  }

  @Mutation
  public clearSonarGroupAlert(): void {
    this.sonarGroupAlert = emptyAlert();
  }

  @Mutation
  public setSonarGroupTableAlert(data: Alert): void {
    this.sonarGroupTableAlert = data;
  }

  @Mutation
  public clearSonarGroupTableAlert(): void {
    this.sonarGroupTableAlert = emptyAlert();
  }

  @Mutation
  public setSonarGroupDetailAlert(data: Alert): void {
    this.sonarGroupDetailAlert = data;
  }

  @Mutation
  public clearSonarGroupDetailAlert(): void {
    this.sonarGroupDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Mutation
  public setSonarServerId(data: string): void {
    this.sonarServerId = data;
  }

  @Mutation
  public setForceDelete(data: boolean): void {
    this.forceDelete = data;
  }
  @Mutation
  public setNotExistingSonarGroup(data: boolean): void {
    this.notExistingSonarGroup = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      alert = new Alert(error.response?.status, error.response?.data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setSonarGroupAlert", alert);
    return false;
  }

  @Action
  public async storeSonarGroup(
    sonarGroup: InterfaceSonarGroup
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}`, {
        name: sonarGroup.name,
        organizationId: sonarGroup.organizationId,
        permission: sonarGroup.permission,
      })
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getSonarGroups(
    searchRequest: SearchByKeywordRequest
  ): Promise<boolean> {
    this.context.commit("setInProgress", true);
    this.context.commit("clearSonarGroupTableAlert");
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageData.options.page}`,
      pageSize: `${searchRequest.pageData.options.itemsPerPage}`,
    }).toString();
    let searchKeywordWithSonarServerId = "";
    if (searchRequest.keyword != "" && searchRequest.keyword != undefined) {
      searchKeywordWithSonarServerId =
        "/search/" + this.sonarServerId + "/" + searchRequest.keyword;
    }
    return axios
      .get(`${baseApiUrl}${searchKeywordWithSonarServerId}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarGroups", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setSonarGroupTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getSonarGroup(id: string): Promise<boolean> {
    this.context.commit("setSelectedSonarGroup", emptySonarGroup());
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedSonarGroup", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setSonarGroupDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async getSonarGroupUserMembership(
    pagedSonarGroupMembership: InterfacePagedSonarGroupMembership
  ): Promise<boolean> {
    this.context.commit("setSonarGroupUserMembership", []);
    const params = new URLSearchParams({
      pageIndex: `${pagedSonarGroupMembership.pageIndex}`,
      pageSize: `${pagedSonarGroupMembership.pageSize}`,
    }).toString();
    return axios
      .get(
        `${baseApiUrl}/${pagedSonarGroupMembership.sonarGroupId}/users?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarGroupUserMembership", response.data.list);
        this.context.commit("setPageDataSonarGroupUsers", response.data.paging);
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async addSonarUserToSonarGroup(sonarUserId: string): Promise<boolean> {
    const pagedPortalGroupMembership: InterfacePagedSonarGroupMembership = {
      sonarGroupId: this.selectedSonarGroup.id,
      pageIndex: this.pageDataSonarGroupUsers.pageIndex,
      pageSize: this.pageDataSonarGroupUsers.pageSize,
    };
    return axios
      .put(
        `${baseApiUrl}/${this.selectedSonarGroup.id}/add-user/${sonarUserId}`
      )
      .then(() => {
        this.context.dispatch("getSonarGroup", this.selectedSonarGroup.id);
        return this.context.dispatch(
          "getSonarGroupUserMembership",
          pagedPortalGroupMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async removeSonarUserFromSonarGroup(
    sonarUserId: string
  ): Promise<boolean> {
    const pagedPortalGroupMembership: InterfacePagedSonarGroupMembership = {
      sonarGroupId: this.selectedSonarGroup.id,
      pageIndex: this.pageDataSonarGroupUsers.pageIndex,
      pageSize: this.pageDataSonarGroupUsers.pageSize,
    };
    return axios
      .put(
        `${baseApiUrl}/${this.selectedSonarGroup.id}/remove-user/${sonarUserId}`
      )
      .then(() => {
        this.context.dispatch("getSonarGroup", this.selectedSonarGroup.id);
        return this.context.dispatch(
          "getSonarGroupUserMembership",
          pagedPortalGroupMembership
        );
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getOrganizationSonarGroupMembership(
    organizationId: string
  ): Promise<boolean> {
    this.context.commit("setSonarGroups", []);
    const params = new URLSearchParams({
      pageIndex: `1`,
      pageSize: `100`,
    }).toString();
    return axios
      .get(
        `${environmentConfig(
          "VUE_APP_BACKEND_PATH"
        )}/api/organizations/${organizationId}/sonar-groups?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarGroups", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        return true;
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      });
  }

  @Action
  public async updateSonarGroup(
    sonarGroup: InterfaceSonarGroup
  ): Promise<boolean> {
    return axios
      .put(`${baseApiUrl}/${sonarGroup.id}`, sonarGroup)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  async deleteSonarGroup(sonarGroup: InterfaceSonarGroup): Promise<boolean> {
    try {
      if (this.forceDelete && this.notExistingSonarGroup) {
        await axios.delete(`${baseApiUrl}/${sonarGroup.id}?force=true`);
        this.context.commit("setForceDelete", false);
        return true;
      } else {
        await axios.delete(`${baseApiUrl}/${sonarGroup.id}`);
        return true;
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 404) {
        this.context.dispatch("handleError", axiosError);
        this.context.commit("setNotExistingSonarGroup", true);
        return false;
      } else {
        return this.context.dispatch("handleError", axiosError);
      }
    }
  }

  @Action
  public async searchSonarGroup(
    searchRequest: InterfaceSonarGroupSearchRequest
  ): Promise<InterfaceSonarGroupSearchResult> {
    this.context.commit("setFoundSonarGroups", []);
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageIndex}`,
      pageSize: `${searchRequest.pageSize}`,
    }).toString();
    return axios
      .get(
        `${baseApiUrl}/search/${searchRequest.sonarServerId}/${searchRequest.name}?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setFoundSonarGroups", response.data.list);
        return <InterfaceSonarGroupSearchResult>{
          paging: response.data.paging,
          groups: response.data.list,
        };
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return <InterfaceSonarGroupSearchResult>{};
      });
  }
}

export default SonarGroups;
