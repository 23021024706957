import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  InterfaceCostAzureSp,
  emptyCostAzureSp,
} from "@/models/CostAzureSp.model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse } from "axios";
import environmentConfig from "@/utils/environmentConfig";

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/sp`;

@Module({ namespaced: true })
class CostAzureSp extends VuexModule {
  public selectedAzureSpCost: InterfaceCostAzureSp = emptyCostAzureSp();
  public azureSpCostDetailAlert: Alert = emptyAlert();
  public costAzureSpTableAlert: Alert = emptyAlert();
  public selectedAzureSpCostsList: InterfaceCostAzureSp[] = [];
  public billingMonth = "";
  public rangeDate: string[] = [];

  @Mutation
  public setSelectedAzureSpCost(data: InterfaceCostAzureSp): void {
    this.selectedAzureSpCost = data;
  }

  @Mutation
  public setAzureSpCostDetailAlert(data: Alert): void {
    this.azureSpCostDetailAlert = data;
  }

  @Mutation
  public setSelectedAzureSpCostsList(data: InterfaceCostAzureSp[]): void {
    this.selectedAzureSpCostsList = data;
  }

  @Mutation
  public setAzureSpCostListAlert(data: Alert): void {
    this.costAzureSpTableAlert = data;
  }

  @Mutation
  public clearAzureSpCostListAlert(): void {
    this.costAzureSpTableAlert = emptyAlert();
  }

  @Mutation
  public setBillingMonth(data: string): void {
    this.billingMonth = data;
  }
  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  async getAzureSpCosts(): Promise<boolean> {
    const allDates: InterfaceCostAzureSp[] = [];
    try {
      await Promise.all(
        this.rangeDate.map((date) => {
          return axios
            .get(`${baseApiUrl}?month=${date}`)
            .then((response: AxiosResponse) => {
              // For each item in response data push to allDates
              response.data.forEach((item: InterfaceCostAzureSp) => {
                allDates.push(item);
              });
            });
        })
      );
      this.context.commit("setSelectedAzureSpCostsList", allDates);
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      let alert: Alert;
      if (axiosError.response) {
        alert = new Alert(
          axiosError.response.status,
          axiosError.response.data.message
        );
      } else {
        alert = new Alert(0, axiosError.message);
      }
      this.context.commit("setAzureSpCostListAlert", alert, { root: true });
      return false;
    }
  }

  @Action
  public async getAzureSpCost(fetchParams: FetchParams): Promise<boolean> {
    const params = new URLSearchParams({
      costCenter: fetchParams.costCenter + "",
      billingMonth: fetchParams.billingMonth,
    }).toString();
    this.context.commit("setSelectedAzureSpCost", emptyCostAzureSp());
    return axios
      .get(`${baseApiUrl}/report?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedAzureSpCost", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setAzureSpCostDetailAlert", alert);
        return false;
      });
  }
}

export default CostAzureSp;
