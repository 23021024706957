import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  emptyAzureUser,
  InterfaceAzureUser,
  InterfaceAzureUserSearchRequest,
  InterfaceAzureUserSearchResult,
} from "@/models/AzureUser.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/azure/users`;

@Module({ namespaced: true })
class AzureUsers extends VuexModule {
  public pageDataSearchAzureUsers: PageData = emptyPageData();
  public pageDataAzureUsers: PageData = emptyPageData();
  public selectedAzureUser: InterfaceAzureUser = emptyAzureUser();
  public foundAzureUsers: InterfaceAzureUser[] = [];
  public azureUserAlert: Alert = emptyAlert();
  public inProgress = false;

  @Mutation
  public setFoundAzureUsers(data: InterfaceAzureUser[]): void {
    this.foundAzureUsers = data;
  }

  @Mutation
  public setAzureUserAlert(data: Alert): void {
    this.azureUserAlert = data;
  }

  @Mutation
  public clearAzureUserAlert(): void {
    this.azureUserAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      alert = new Alert(error.response?.status, error.response?.data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setAzureUserAlert", alert);
    return false;
  }

  @Action
  public async searchAzureUser(
    searchRequest: InterfaceAzureUserSearchRequest
  ): Promise<InterfaceAzureUserSearchResult> {
    this.context.commit("setFoundAzureUsers", []);
    const params = new URLSearchParams({
      pageIndex: `1`,
      pageSize: `${searchRequest.pageSize}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}/search/${searchRequest.displayName}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setFoundAzureUsers", response.data.list);
        return <InterfaceAzureUserSearchResult>{
          paging: response.data.paging,
          azureUsers: response.data.list,
        };
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return <InterfaceAzureUserSearchResult>{};
      });
  }
}

export default AzureUsers;
