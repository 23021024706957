import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  InterfaceCostAzureHub,
  emptyCostAzureHub,
} from "@/models/CostAzureHub.model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import axios, { AxiosError, AxiosResponse } from "axios";
import environmentConfig from "@/utils/environmentConfig";

interface FetchParams {
  costCenter: string;
  billingMonth: string;
}

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/azure/hub`;

@Module({ namespaced: true })
class CostAzureHub extends VuexModule {
  public selectedAzureHubCost: InterfaceCostAzureHub = emptyCostAzureHub();
  public azureHubCostDetailAlert: Alert = emptyAlert();
  public costAzureHubTableAlert: Alert = emptyAlert();
  public selectedAzureHubCostsList: InterfaceCostAzureHub[] = [];
  public dataFactoryConnections: string[] = [];
  public subscriptionVnetPeerings: string[] = [];

  public billingMonth = "";
  public rangeDate: string[] = [];

  @Mutation
  public setDataFactoryConnections(data: string[]): void {
    this.dataFactoryConnections = data;
  }

  @Mutation
  public setSubscriptionVnetPeerings(data: string[]): void {
    this.subscriptionVnetPeerings = data;
  }

  @Mutation
  public setSelectedAzureHubCost(data: InterfaceCostAzureHub): void {
    this.selectedAzureHubCost = data;
  }

  @Mutation
  public setAzureHubCostDetailAlert(data: Alert): void {
    this.azureHubCostDetailAlert = data;
  }

  @Mutation
  public setSelectedAzureHubCostsList(data: InterfaceCostAzureHub[]): void {
    this.selectedAzureHubCostsList = data;
  }

  @Mutation
  public setAzureHubCostListAlert(data: Alert): void {
    this.costAzureHubTableAlert = data;
  }

  @Mutation
  public clearAzureHubCostListAlert(): void {
    this.costAzureHubTableAlert = emptyAlert();
  }

  @Mutation
  public setBillingMonth(data: string): void {
    this.billingMonth = data;
  }
  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  async getAzureHubCosts(): Promise<boolean> {
    const allDates: InterfaceCostAzureHub[] = [];
    try {
      await Promise.all(
        this.rangeDate.map((date) => {
          return axios
            .get(`${baseApiUrl}?month=${date}`)
            .then((response: AxiosResponse) => {
              // For each item in response data push to allDates
              response.data.forEach((item: InterfaceCostAzureHub) => {
                allDates.push(item);
              });
            });
        })
      );
      this.context.commit("setSelectedAzureHubCostsList", allDates);
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      let alert: Alert;
      if (axiosError.response) {
        alert = new Alert(
          axiosError.response.status,
          axiosError.response.data.message
        );
      } else {
        alert = new Alert(0, axiosError.message);
      }
      this.context.commit("setAzureHubCostListAlert", alert, { root: true });
      return false;
    }
  }

  @Action
  public async getAzureHubCost(fetchParams: FetchParams): Promise<boolean> {
    const params = new URLSearchParams({
      costCenter: fetchParams.costCenter + "",
      billingMonth: fetchParams.billingMonth,
    }).toString();
    this.context.commit("setSelectedAzureHubCost", emptyCostAzureHub());
    return axios
      .get(`${baseApiUrl}/report?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedAzureHubCost", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setAzureHubCostDetailAlert", alert);
        return false;
      });
  }
}

export default CostAzureHub;
