import { Alert, emptyAlert } from "@/models/Alert.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import {
  emptySonarServer,
  InterfaceSonarServer,
} from "@/models/SonarServer.model";
import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/sonar-servers`;

@Module({ namespaced: true })
class SonarServers extends VuexModule {
  public selectedSonarServer: InterfaceSonarServer = emptySonarServer();
  public existingSonarServers: InterfaceSonarServer[] = [];
  public pageDataSonarServers: PageData = emptyPageData();
  public pageDataMaintenance: PageData = emptyPageData();
  public sonarServerAlert: Alert = emptyAlert();
  public sonarServerTableAlert: Alert = emptyAlert();
  public sonarServerDetailAlert: Alert = emptyAlert();
  public inProgress = false;

  @Mutation
  public setSonarServers(data: InterfaceSonarServer[]): void {
    this.existingSonarServers = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataSonarServers.totalItems = pageData.totalItems;
    this.pageDataSonarServers.totalPages = pageData.totalPages;
  }

  @Mutation
  public setPageDataMaintenance(pageData: PageData): void {
    this.pageDataMaintenance.totalItems = pageData.totalItems;
    this.pageDataMaintenance.totalPages = pageData.totalPages;
  }

  @Mutation
  public setSelectedSonarServer(data: InterfaceSonarServer): void {
    this.selectedSonarServer = data;
  }

  @Mutation
  public setSonarServerAlert(data: Alert): void {
    this.sonarServerAlert = data;
  }

  @Mutation
  public clearSonarServerAlert(): void {
    this.sonarServerAlert = emptyAlert();
  }

  @Mutation
  public setSonarServerTableAlert(data: Alert): void {
    this.sonarServerTableAlert = data;
  }

  @Mutation
  public clearSonarServerTableAlert(): void {
    this.sonarServerTableAlert = emptyAlert();
  }

  @Mutation
  public setSonarServerDetailAlert(data: Alert): void {
    this.sonarServerDetailAlert = data;
  }

  @Mutation
  public clearSonarServerDetailAlert(): void {
    this.sonarServerDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      alert = new Alert(error.response?.status, error.response?.data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setSonarServerAlert", alert);
    return false;
  }

  @Action
  public async storeSonarServer(
    sonarServer: InterfaceSonarServer
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}`, {
        name: sonarServer.name,
        accessToken: sonarServer.accessToken,
        url: sonarServer.url,
      })
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getSonarServers(pageData: PageData): Promise<boolean> {
    this.context.commit("setSonarServers", []);
    this.context.commit("setInProgress", true);
    this.context.commit("clearSonarServerTableAlert");
    const params = new URLSearchParams({
      pageIndex: `${pageData.options.page}`,
      pageSize: `${pageData.options.itemsPerPage}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSonarServers", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setPageDataMaintenance", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setSonarServerTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getSonarServer(id: string): Promise<boolean> {
    this.context.commit("setSelectedSonarServer", emptySonarServer());
    this.context.commit("clearSonarServerDetailAlert");
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedSonarServer", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setSonarServerDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async updateSonarServer(
    sonarServer: InterfaceSonarServer
  ): Promise<boolean> {
    return axios
      .put(`${baseApiUrl}/${sonarServer.id}`, sonarServer)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async updateSonarServerAccessToken(
    sonarServer: InterfaceSonarServer
  ): Promise<boolean> {
    return axios
      .put(
        `${baseApiUrl}/${sonarServer.id}/access-token`,
        `{"accessToken": "${sonarServer.accessToken}"}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async deleteSonarServer(
    sonarServer: InterfaceSonarServer
  ): Promise<boolean> {
    return axios
      .delete(`${baseApiUrl}/${sonarServer.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async verifyAccessToken(
    sonarServer: InterfaceSonarServer
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}/verifyToken`, sonarServer)
      .then((response: AxiosResponse) => {
        if (response.data.verified === false) {
          this.context.commit(
            "setSonarServerAlert",
            new Alert(200, "Access token is invalid")
          );
        }
        return response.data.verified;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async syncUsers(sonarServerId: string): Promise<boolean> {
    return axios
      .get(`${baseApiUrl}/${sonarServerId}/syncUsers`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }
}

export default SonarServers;
