import { Alert, emptyAlert } from "@/models/Alert.model";
import {
  emptyCostCenter,
  InterfaceCostCenter,
  InterfaceCostCenterSearchRequest,
  InterfaceCostCenterSearchResult,
  InterfaceCostCenterServicesResult,
} from "@/models/CostCenter.model";
import { emptyPageData, PageData } from "@/models/PageData.model";
import { SearchByKeywordRequest } from "@/models/Search.model";
import environmentConfig from "@/utils/environmentConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";

const baseApiUrl = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/centers`;

const costCenterService = `${environmentConfig(
  "VUE_APP_BACKEND_PATH"
)}/api/costs/reports`;

interface FetchParams {
  search: string;
  page: number;
  organizationId: string;
}

interface MoveCostCenterParams {
  costCenterId: string;
  organizationId: string;
}

@Module({ namespaced: true })
class CostCenters extends VuexModule {
  public existingCostCenters: InterfaceCostCenter[] = [];
  public pageDataCostCenters: PageData = emptyPageData();
  public pageDataSearchCostCenters: PageData = emptyPageData();
  public selectedCostCenter: InterfaceCostCenter = emptyCostCenter();
  public foundCostCenters: InterfaceCostCenter[] = [];
  public costCenterAlert: Alert = emptyAlert();
  public costCenterTableAlert: Alert = emptyAlert();
  public costCenterDetailAlert: Alert = emptyAlert();
  public inProgress = false;
  public costCenterAutocompleteLoadMore = false;
  public billingMonth = "";
  public costCenterServices: InterfaceCostCenterServicesResult[] = [];
  public rangeDate: string[] = [];

  @Mutation
  public setLoadMore(boolean: boolean): void {
    this.costCenterAutocompleteLoadMore = boolean;
  }

  @Mutation
  public setCostCenters(data: InterfaceCostCenter[]): void {
    this.existingCostCenters = data;
  }

  @Mutation
  public setPageData(pageData: PageData): void {
    this.pageDataCostCenters.totalItems = pageData.totalItems;
  }

  @Mutation
  public setSearchPageData(pageData: PageData): void {
    this.pageDataSearchCostCenters.totalPages = pageData.totalPages;
  }

  @Mutation
  public setSelectedCostCenter(data: InterfaceCostCenter): void {
    this.selectedCostCenter = data;
  }

  @Mutation
  public setFoundCostCenters(data: InterfaceCostCenter[]): void {
    this.foundCostCenters = data;
  }

  @Mutation
  public setCostCenterAlert(data: Alert): void {
    this.costCenterAlert = data;
  }

  @Mutation
  public clearCostCenterAlert(): void {
    this.costCenterAlert = emptyAlert();
  }

  @Mutation
  public setCostCenterTableAlert(data: Alert): void {
    this.costCenterTableAlert = data;
  }

  @Mutation
  public clearCostCenterTableAlert(): void {
    this.costCenterTableAlert = emptyAlert();
  }

  @Mutation
  public setCostCenterDetailAlert(data: Alert): void {
    this.costCenterDetailAlert = data;
  }

  @Mutation
  public clearCostCenterDetailAlert(): void {
    this.costCenterDetailAlert = emptyAlert();
  }

  @Mutation
  public setInProgress(data: boolean): void {
    this.inProgress = data;
  }

  @Mutation
  public setCostCenterServices(
    data: InterfaceCostCenterServicesResult[]
  ): void {
    this.costCenterServices = data;
  }

  @Mutation
  public setBillingMonth(data: string): void {
    this.billingMonth = data;
  }
  @Mutation
  public setRangeDate(data: string[]): void {
    this.rangeDate = data;
  }

  @Action
  public handleError(error: AxiosError): boolean {
    let alert: Alert;
    if (error.response) {
      alert = new Alert(error.response?.status, error.response?.data.message);
    } else {
      alert = new Alert(0, error.message);
    }
    this.context.commit("setCostCenterAlert", alert);
    return false;
  }

  @Action
  public async storeCostCenter(
    costCenter: InterfaceCostCenter
  ): Promise<boolean> {
    return axios
      .post(`${baseApiUrl}`, {
        // id: "string",
        name: costCenter.name,
        responsibleId: costCenter.responsibleId,
        responsibleEmail: costCenter.responsibleEmail,
        organizationId: costCenter.organizationId,
        notificationUsers: costCenter.notificationUsers,
      })
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async getCostCenters(
    searchRequest: SearchByKeywordRequest
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageData.options.page}`,
      pageSize: `${searchRequest.pageData.options.itemsPerPage}`,
    }).toString();
    this.context.commit("setInProgress", true);
    this.context.commit("clearCostCenterTableAlert");
    let searchKeyword = "";
    if (searchRequest.keyword != "" && searchRequest.keyword != undefined) {
      searchKeyword = "/search/" + searchRequest.keyword;
    }
    return axios
      .get(`${baseApiUrl}${searchKeyword}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setCostCenters", response.data.list);
        this.context.commit("setPageData", response.data.paging);
        this.context.commit("setInProgress", false);
        return true;
      })
      .catch((error: AxiosError) => {
        this.context.commit("setInProgress", false);
        this.context.commit(
          "setCostCenterTableAlert",
          new Alert(0, error.message)
        );
        return false;
      });
  }

  @Action
  public async getOrganizationCostCenters(
    fetchParams: FetchParams
  ): Promise<boolean> {
    const params = new URLSearchParams({
      pageIndex: fetchParams.page + "",
      pageSize: `5`,
    }).toString();
    let searchKeyword = "";
    if (fetchParams.search != "" && fetchParams.search != null) {
      searchKeyword = "/search/".concat(fetchParams.search);
    }
    this.context.commit("setCostCenters", []);
    return axios
      .get(
        `${baseApiUrl}/organizations/${fetchParams.organizationId}${searchKeyword}?${params}`
      )
      .then((response: AxiosResponse) => {
        this.context.commit("setCostCenters", response.data.list);
        this.context.commit("setSearchPageData", response.data.paging);
        if (response.data.paging.pageIndex < response.data.paging.totalPages) {
          this.context.commit("setLoadMore", true);
        } else {
          this.context.commit("setLoadMore", false);
        }
        return true;
      });
  }

  @Action
  public async getCostCenter(id: string): Promise<boolean> {
    this.context.commit("setSelectedCostCenter", emptyCostCenter());
    return axios
      .get(`${baseApiUrl}/${id}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setSelectedCostCenter", response.data);
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setCostCenterDetailAlert", alert);
        return false;
      });
  }

  @Action
  async getCostCenterServices(id: string): Promise<boolean> {
    const allDates: InterfaceCostCenterServicesResult[] = [];
    try {
      await Promise.all(
        this.rangeDate.map((date) => {
          return axios
            .get(`${costCenterService}/${id}?billingMonth=${date}`)
            .then((response: AxiosResponse) => {
              allDates.push(response.data);
            });
        })
      );
      this.context.commit("setCostCenterServices", allDates);
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      let alert: Alert;
      if (axiosError.response) {
        alert = new Alert(
          axiosError.response.status,
          axiosError.response.data.message
        );
      } else {
        alert = new Alert(0, axiosError.message);
      }
      this.context.commit("setCostCenterDetailAlert", alert, { root: true });
      return false;
    }
  }

  @Action
  public async updateCostCenter(
    costCenter: InterfaceCostCenter
  ): Promise<boolean> {
    return axios
      .put(`${baseApiUrl}/${costCenter.id}`, costCenter)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async changeCostCenterOrganization(
    moveOrgParams: MoveCostCenterParams
  ): Promise<boolean> {
    const params = new URLSearchParams({
      organizationId: moveOrgParams.organizationId,
    }).toString();
    return axios
      .put(`${baseApiUrl}/${moveOrgParams.costCenterId}/move?${params}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        let alert: Alert;
        if (error.response) {
          alert = new Alert(error.response.status, error.response.data.message);
        } else {
          alert = new Alert(0, error.message);
        }
        this.context.commit("setCostCenterDetailAlert", alert);
        return false;
      });
  }

  @Action
  public async deleteCostCenter(
    costCenter: InterfaceCostCenter
  ): Promise<boolean> {
    return axios
      .delete(`${baseApiUrl}/${costCenter.id}`)
      .then(() => {
        return true;
      })
      .catch((error: AxiosError) => {
        return this.context.dispatch("handleError", error);
      });
  }

  @Action
  public async searchCostCenter(
    searchRequest: InterfaceCostCenterSearchRequest
  ): Promise<InterfaceCostCenterSearchResult> {
    this.context.commit("setFoundCostCenters", []);
    const params = new URLSearchParams({
      pageIndex: `${searchRequest.pageIndex}`,
      pageSize: `${searchRequest.pageSize}`,
    }).toString();
    return axios
      .get(`${baseApiUrl}/search/${searchRequest.name}?${params}`)
      .then((response: AxiosResponse) => {
        this.context.commit("setFoundCostCenters", response.data.list);
        return <InterfaceCostCenterSearchResult>{
          paging: response.data.paging,
          costCenters: response.data.list,
        };
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return <InterfaceCostCenterSearchResult>{};
      });
  }
}

export default CostCenters;
